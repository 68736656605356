import "./Subtotal.css";
import React, { useState, useEffect } from "react";
import CurrencyFormat from "react-currency-format";
import { useStateValue } from "./StateProvider";
import { getBasketTotal } from "./reducer";
import axios from "axios";
import CheckoutButton from "./CheckoutButton";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
function Subtotal() {
  const [checkOutLabel, setCheckOutLabel] = useState("");
  const [{ basket, user }, dispatch] = useStateValue(); // pull from data layer
  const [sessionId, setSessionId] = useState(null);
  const stripePromise = loadStripe(
    // no need to hide publishable key :
    "pk_test_51L09miAhbJupDqIgKB1UEJLJ1Zoan23ReiUuJLAE9QuMgzTiwEVq0fDEUpad0LNOpFWzCmUsNyVa5nbQ549fZrBv00rGuUORL3"
  );
  useEffect(() => {
    // if user uses back button to come back from stripe checkout session
    // empty the basket
    if (sessionId && user) {
      dispatch({
        type: "EMPTY_BASKET",
      });
      setSessionId(null);
    }

    if (!user) {
      // if user not logged in
      setCheckOutLabel(
        `You must Login before \n you can proceed to check out !`
      );
      return;
    } else if (basket.length === 0) {
      setCheckOutLabel(`You must select the item(s) you want to check out !`);
      return;
    }

    axios
      .post(
        "https://us-central1-dollarealmsllc.cloudfunctions.net/api/checkout",
        basket
        // `http://localhost:5000/dollarealmsllc/us-central1/api/checkout`,        basket
      )
      .then((response) => {
        setSessionId(response.data.id);
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  return (
    <div className="subtotal">
      <CurrencyFormat
        renderText={(
          value // see below code , value={  getBasketTotal ( basket )  }
        ) => (
          <>
            <p>Subtotal( {basket.length} items):</p>
            <small className="subtotal__gift">
              <strong> {value} </strong>
              {/* <input type="checkbox" /> */}
              {/* Could this order be a gift */}
            </small>
          </>
        )}
        decimalScale={2}
        value={getBasketTotal(basket)} // getBasketTotal() is a function of the reducer.js
        displayType={"text"} //   configures the diplay of the <> Subtotal , checkbox </> above
        thousandSeparator={true}
        prefix={"$"}
      />
      {/* end of currency format */}
      <Elements stripe={stripePromise}>
        {user && !sessionId && <h6 className="please-wait">loading..</h6> && (
          <h6 className="please-wait">please wait...</h6>
        )}
        {user && sessionId && <CheckoutButton sessionId={sessionId} />}
      </Elements>
    </div>
  );
}

export default Subtotal;

import React from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { useStateValue } from "./StateProvider";
const CheckoutButton = ({ sessionId }) => {
  const [{ basket, user }, dispatch] = useStateValue(); // pull from data layer
  const stripe = useStripe();
  const handleClick = async () => {
    try {
      //1. empty the basket
      dispatch({
        type: "EMPTY_BASKET",
      });
      //2. use the collected basket info to load stripe checkout session
      const { error } = await stripe.redirectToCheckout({ sessionId });
      if (error) {
        console.error("Error redirecting to checkout:", error);
      }
    } catch (error) {
      console.error("Unexpected error:", error);
    }
  };

  return (
    <button onClick={handleClick} disabled={!stripe}>
      <h3>Proceed to Check out</h3>
    </button>
  );
};

export default CheckoutButton;
